
import { computed, defineComponent, ref, watch } from "vue";
import Dropdown1 from "@/components/dropdown/Dropdown1.vue";
import { toolbarWidthFluid } from "@/core/helpers/config";
import orderReportToolbar from "@/components/toolbar/orderReportToolbar.vue";
import promotionReportToolbar from "@/components/toolbar/promotionReportToolbar.vue";
import riderReportToolbar from "@/components/toolbar/riderReportToolbar.vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import monitorRiderToolbar from "@/components/toolbar/management/monitorRiderToolbar.vue";
import walletTransactionToolBar from "@/components/toolbar/management/walletTransactionToolBar.vue";
import TopupFormModal from "@/components/toolbar/TopupFormModal.vue";
import billToolbar from "@/components/toolbar/management/billToolbar.vue";
import bankToolbar from "@/components/toolbar/management/bankToolbar.vue";
export default defineComponent({
  name: "KToolbar",
  props: {
    breadcrumbs: Array,
    title: String,
  },
  components: {
    Dropdown1,
    orderReportToolbar,
    promotionReportToolbar,
    riderReportToolbar,
    monitorRiderToolbar,
    walletTransactionToolBar,
    TopupFormModal,
    billToolbar,
    bankToolbar,
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const route = useRoute();

    const riderList = computed(() => {
      return store.getters["RiderModule/getRiderListSelection"];
    });

    const orderReportConfig = computed(() => {
      return store.state.ReportModule.orderReportConfig;
    });

    const promotionReportConfig = computed(() => {
      return store.state.ReportModule.promotionReportConfig;
    });

    const riderReportConfig = computed(() => {
      return store.state.ReportModule.riderReportConfig;
    });

    let isBrowseReport = ref(false);
    const isLoading = ref(false);
    // eslint-disable-next-line vue/return-in-computed-property
    const toolbarContent = computed(() => {
      if (props.title == "Order Report") {
        return "orderReportToolbar";
      } else if (props.title == "Promotion Report") {
        return "promotionReportToolbar";
      } else if (props.title == "Rider Report") {
        return "riderReportToolbar";
      } else if (props.title == "Monitor rider") {
        return "monitorRiderToolbar";
      } else if (props.title == "Wallet & Transaction") {
        return "walletTransactionToolBar";
      } else if (props.title == "Invoice Management") {
        return "billToolbar";
      } else if (props.title == "Bank Information") {
        return "bankToolbar";
      }
    });

    function fetchOrderReport() {
      if (
        orderReportConfig.value.orderType == null ||
        orderReportConfig.value.orderStatus == null ||
        orderReportConfig.value.startDate == "" ||
        orderReportConfig.value.endDate == ""
      ) {
        return;
      }
      isLoading.value = true;
      store.commit(Mutations.SET_ORIGINAL_ORDER_CONFIG, null);
      let districtList = JSON.parse(
        window.localStorage.getItem("user_district") || "[]"
      );
      if (orderReportConfig.value.orderType == 2) {
        return store.dispatch(Actions.GET_ORDER_REPORT_MESSENGER, {
          credentials: {
            district: districtList.map((val) => val.district_id),
          },
        });
      } else {
        return store.dispatch(Actions.GET_ORDER_REPORT, {
          credentials: {
            district: districtList.map((val) => val.district_id),
          },
        });
      }
    }
    function fetchPromotionReport() {
      if (
        promotionReportConfig.value.startDate == "" ||
        promotionReportConfig.value.endDate == ""
      ) {
        return;
      }
      isLoading.value = true;
      store.commit(Mutations.SET_ORIGINAL_PROMOTION_CONFIG, null);
      let districtList = JSON.parse(
        window.localStorage.getItem("user_district") || "[]"
      );
      return store.dispatch(Actions.GET_PROMOTION_REPORT, {
        credentials: {
          district: districtList.map((val) => val.district_id),
        },
      });
    }

    function fetchRiderReport() {
      if (
        riderReportConfig.value.startDate == "" ||
        riderReportConfig.value.endDate == ""
      ) {
        return;
      }
      isLoading.value = true;
      store.commit(Mutations.SET_ORIGINAL_RIDER_CONFIG, null);
      let riderId = 0;
      if (riderReportConfig.value.riderName != "") {
        riderId = riderList.value.find(
          (val) => val.name == riderReportConfig.value.riderName
        )?.value;
        if (!riderId) {
          riderId = -1;
        }
      }

      const credentials = {
        rider_id: riderId,
      };

      return store.dispatch(Actions.GET_RIDER_REPORT, credentials);
    }

    async function getReport() {
      if (props.title == "Order Report") {
        await fetchOrderReport();
      } else if (props.title == "Promotion Report") {
        await fetchPromotionReport();
      } else if (props.title == "Rider Report") {
        await fetchRiderReport();
      }

      isLoading.value = false;
      isBrowseReport.value = false;
    }
    async function withdraw() {
      router.push({
        path: `/management/wallet-transaction/withdraw`,
      });
    }
    watch(
      () => props.title,
      () => {
        isBrowseReport.value = false;
        isLoading.value = false;
      }
    );

    return {
      route,
      isLoading,
      isBrowseReport,
      toolbarContent,
      getReport,
      toolbarWidthFluid,
      withdraw,
    };
  },
});
